@font-face{font-family:'Hanken Grotesk';src:url(../fonts/HankenGrotesk-Light.woff2) format("woff2"),url(../fonts/HankenGrotesk-Light.woff) format("woff");font-weight:300;font-style:normal;font-display:swap}
@font-face{font-family:'Hanken Grotesk';src:url(../fonts/HankenGrotesk-Regular.woff2) format("woff2"),url(../fonts/HankenGrotesk-Regular.woff) format("woff");font-weight:400;font-style:normal;font-display:swap}
@font-face{font-family:'Hanken Grotesk';src:url(../fonts/HankenGrotesk-Medium.woff2) format("woff2"),url(../fonts/HankenGrotesk-Medium.woff) format("woff");font-weight:500;font-style:normal;font-display:swap}
@font-face{font-family:'Hanken Grotesk';src:url(../fonts/HankenGrotesk-Bold.woff2) format("woff2"),url(../fonts/HankenGrotesk-Bold.woff) format("woff");font-weight:700;font-style:normal;font-display:swap}
:root{
    --white:#FFF;
    --black:#000;
    --bg-green:#E4EDE8;
    --light-gray:#C3CCD3;
    --white-secondary:#FBFCFB;
    --gray:#666;
    --green:#83B797;
    --white-transparent:rgba(255, 255, 255, 0.3);
    --black-secondary:#212121;
    --font-light:300;
    --font-regular:normal;
    --font-medium:500;
    --font-bold:bold
}
body{font-family:'Hanken Grotesk';font-weight:500;box-sizing:border-box;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-rendering:optimizeLegibility;color:var(--black)}
html body{font-family:'Hanken Grotesk';font-weight:500;color:var(--black);height: 100%;}
main {flex: 1;}
.wrapper {display: flex;flex-direction: column;min-height: 100vh;}
body{background-color:var(--bg-green)!important}
h1,h2,h3,h4,h5,h6{font-weight:700 !important}
ul{list-style:none}
body a{text-decoration:none}
img{max-width:100%;vertical-align:bottom}
.container .btn{font-size:20px;background-color:var(--black-secondary);color:var(--white);border-radius:10px;padding:10px 25px;font-weight:var(--font-medium);height:60px;line-height:36px;border:0}
.container{max-width:1100px !important;margin-inline:auto}
.container .btn:hover{background-color:var(--green);color:var(--black-secondary)}
.container .btn.secondary{background-color:var(--green);color:var(--black-secondary)}
.container .btn.secondary:hover{background-color:var(--black-secondary);color:var(--white)}
.btn.fluid{width:100%}
h1.title{font-size:38px;line-height:50px;margin-bottom:20px;font-weight:var(--font-bold)}
.title.h3{font-size:26px;line-height:34px;font-weight:var(--font-bold);margin-bottom:10px}
.title.h2{font-size:32px;line-height:42px;font-weight:var(--font-bold);margin-bottom:20px}
section{margin-bottom:130px}
.header{padding:40px 0}
header .logo{display: flex;position: relative; float: left;align-items: center;justify-content: left; color: var(--black);font-size: 22px;font-weight:var( --font-bold)}
header .logo:hover{color: var(--black);font-weight:var( --font-bold)}
.header .btn.secondary{font-size:16px;height:auto;padding:9px 22px;line-height:22px}
.join-sec{position:relative}
.join-sec:before{position:absolute;content:'';width:100%;height:100%;top:0;bottom:0;left:0;right:0;background-image:url(../images/shape-component.svg);background-size:100%;background-position:center;background-repeat:no-repeat;z-index:-1;opacity:.2}
.join-sec .content-block{max-width:620px;margin-inline:auto;padding:20px 0 60px}
.join-sec p{font-weight:var(--font-medium);line-height:23px;margin-bottom:30px;font-size:18px}
body .join-sec .btn{min-width:260px}
.img-with-text p,.footer-main-content p{font-size:20px;line-height:30px;font-weight:var(--font-light)}
.client-expectations-sec p{margin-bottom:0}
.client-expectations-sec .row{align-items:center}
.post-sec h2.title{text-align:center;margin-bottom:45px}
.post-card{border-radius:30px;overflow:hidden;height:100%;background-color:var(--white)}
.post-card img{width:100%}
.post-card .content{padding:16px 25px 22px;background-color:var(--white)}
.post-main>.row{display:grid;grid-template-columns:50% 50%;padding:0;margin-inline:-20px;row-gap:40px;margin-bottom:0}
.post-main>.row>li{width:100%;padding:0 20px}
.post-card .content .title{font-size:20px;line-height:26px;font-weight:var(--font-bold);margin-bottom:18px;-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis;white-space:normal; height: calc(1.25em * 2);}
body .post-card .btn{font-size:17px;line-height:22px;height:50px;padding:14px 25px;min-width:136px}
.post-main .btn.fluid{background-color:var(--white);color:var(--black);font-size:17px;line-height:22px;height:50px;padding:14px;margin-top:40px}
.post-main .btn.fluid:hover{background-color:var(--green);color:var(--black-secondary)}
.post-sec .container>.row{display:grid;margin:0;grid-template-columns:1fr 300px;gap:40px;align-items:flex-start}
.post-sec .container>.row>div{padding:0;width:100%; height: auto;}
.post-sec .container>.row>.post-main{padding:0;width:100%; height: 100%;}
.post-sec .post-aside{background-color:var(--white-transparent);padding:20px 30px!important;border-radius:30px}
.social-media{display:flex;align-items:center;gap:10px}
/* .social-media .social-icon{width:40px;height:40px;font-size:0;border-radius:40px;background-image:url(../images/twitter-white.svg);background-position:center;background-repeat:no-repeat;background-size:cover;background-color:var(--black);transition:.2s all}
.social-media .social-icon:hover{background-color:var(--green)}
.social-media .social-icon.facebook{background-image:url(../images/facebook-white.svg)}
.social-media .social-icon.instagram{background-image:url(../images/instagram-white.svg)}
.social-media .social-icon.linkedin{background-image:url(../images/linkedin-white.svg)}
.social-media .social-icon.twitter:hover{background-image:url(../images/twitter-black.svg)}
.social-media .social-icon.facebook:hover{background-image:url(../images/facebook-black.svg)}
.social-media .social-icon.instagram:hover{background-image:url(../images/instagram-black.svg)}
.social-media .social-icon.linkedin:hover{background-image:url(../images/linkedin-black.svg)} */


.social-media .social-icon{
    width:40px;height:40px;font-size:0;border-radius:40px;
    display: block;
}
.social-media .social-icon .social-img{
    width:40px;height:40px;
    border-radius:40px;
}


.post-sec .post-aside>div{margin-bottom:35px}
.post-sec .post-aside>div:last-child{margin-bottom:0}
.links-block{margin:15px 0 0;padding:0}
.links-block .link-item a{text-decoration:none;color:var(--black-secondary);font-weight:var(--font-regular);font-size:16px;line-height:22px;display:block;padding:2px 0 2px 25px;position:relative}
.links-block .link-item{margin:10px 0}
.links-block .link-item a:before{position:absolute;content:'';border-top:2px solid;border-right:2px solid;width:11px;height:11px;transform:rotate(135deg);left:0;top:8px;border-radius:0;transform:rotate(45deg)}
/* .links-block .link-item a:hover{color:var(--green)} */
.popular-post_item-wrapper{display:grid;padding:0}
.popular-post_item-main{display:grid;grid-template-columns:56px 1fr;align-items:center}
.popular-post_item-main .content-block{padding-left:14px}
.popular-post_item-main .content-block .title{font-size:14px;line-height:18px;color:var(--black-secondary);text-decoration:none;-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis;white-space:normal;max-height:36px;font-weight:var(--font-regular);margin-bottom:0}
.popular-post_item-main .content-block span{color:var(--light-gray);font-size:12px;line-height:16px}
.popular-post_item{margin:10px 0}
.popular-post_item:last-child{margin-bottom:0}
.footer-main{padding:80px 0 0;background-image:linear-gradient(var(--white),var(--bg-green))}
.copyright{padding:15px 0;background-color:var(--white-secondary)}
/* .copyright .social-media .social-icon{width:30px;height:30px} */
.copyright p{margin:0;font-size:16px;font-weight:var(--font-light);color:var(--black)}
.copyright .row{align-items:center}
.footer-main-content{text-align:center}
.footer-main-content p{max-width:772px;margin-inline:auto}
.newsletter{max-width:600px;margin-inline:auto;display:grid;grid-template-columns:1fr 160px;gap:20px;margin-top:60px}
.newsletter input{border:1px solid var(--green);border-radius:10px;background-color:var(--bg-green);padding:12px 28px;font-size:20px;color:var(--black);height:60px;font-weight:var(--font-light)}
.newsletter input::-ms-input-placeholder{color:var(--black)}
.newsletter input::placeholder{color:var(--black)}
.footer-component{padding-top:130px;text-align:right;padding-right:100px;padding-bottom:10px}
.footer-component img{transition:.3s ease-in-out;-webkit-transition:.3s;-moz-transition:.3s;-ms-transition:.3s;-o-transition:.3s}
.footer-component img:hover{transition:.3s ease-in-out;-webkit-transition:.3s;-moz-transition:.3s;-ms-transition:.3s;-o-transition:.3s;transform:rotate(15deg);-webkit-transform:rotate(15deg);-moz-transform:rotate(15deg);-o-transform:rotate(15deg);-ms-transform:rotate(15deg)}
body input:focus-visible{border:1px solid var(--green);outline:1px solid var(--green)}
/* .blog-details-page .container{max-width:920px !important} */
.br-30{border-radius:30px}
.blog-post-detail .blog-detail-title{font-size:46px;line-height:1.3;margin-bottom:20px}
.blog-post-detail .content-block{padding-top:20px;padding-bottom:10px}
.post-info{font-size:20px;line-height:1.2;color:var(--gray);font-weight:var(--font-regular)}
.blog-post-detail .img-block{margin-bottom:40px}
.blog-details-page h2{font-size:30px;line-height:1}
.post-content p{font-size:18px;line-height:1.8;}
/* .post-content br{display: none;} */
.related-posts .post-card .content .title{font-size:16px;line-height:1.2;margin-bottom:10px;-webkit-line-clamp:3;}
.related-posts .post-card .content .btn{font-size:16px;line-height:1.2;height:40px;padding:10px 20px;min-width:103px}
.back-btn {color: var(--gray);font-size: 20px;line-height: 1.2;font-weight: var(--font-regular);display: flex;align-items: center;justify-content: left;}
.back-btn img{margin-right: 5px;}
.newsletter .btn:active{background-color:var(--black-secondary);color:var(--white);}
.newsletter .btn:focus{background-color:var(--black-secondary);color:var(--white);}
.newsletter .btn:hover{background-color:var(--green);color:var(--black-secondary)}
.btn-loader{width: 22px; height: 22px;}
header .logo img{margin-right: 12px;}
header .logo .happy-logo{position:relative;transition: opacity 0.2s ease-in-out;}
header .logo .happy-logo2{position: absolute;opacity: 0;transition: opacity 0.2s ease-in-out;}
header .logo:hover .happy-logo2{opacity: 1;transition: opacity 0.2s ease-in-out;}
header .logo:hover .happy-logo{opacity:0;transition: opacity 0.2s ease-in-out;}
.related-posts .row.ps-0 {
    --bs-gutter-x: 42px;
}

.join-us_block .title.h3{
    font-size: 16px;
    margin-bottom: 5px;
}

.avatar-details-cover{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:10px;
    padding: 0 5px;
}
.avatar-details-cover .avatar-details{
    display: flex;
    align-items: center;
    justify-content: left

}
.avatar-details-cover .avatar-details .avatar-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.avatar-details-cover .avatar-details .post-info{
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 18px;
}
.avatar-details-cover .avatar-details .post-info span{
    font-size:14px;
    color: var(--gray);
    font-weight: var(--font-regular);
    text-transform: capitalize;
    display: block;
    padding-top: 0;
}
.avatar-details-cover .post-info{
    margin-bottom: 0;
}
.share-cover {
    margin-top: 30px;
}
.share-cover h2{
    font-size:30px;
    line-height: 34px;
    font-weight: var(--font-bold);
    margin-bottom: 10px;
    text-transform: capitalize;
}

.share-cover .share-icon{
display: flex;
align-items: center;
justify-content: left;
gap:10px
}
.share-cover .share-icon .social-icon2 {
    width: 35px;
    height: 35px;

}
.card-no-data{
    background-color: var(--white-transparent);
    padding: 20px 30px !important;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.happy-logo-img {
    text-align: center;
}
.happy-logo-img img{
    width:80px;
    height:80px;
    object-fit: contain;
}
.happy-logo-img p{
    font-size: 20px;
    color: var(--black);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
.filter-list a.active{
    color: #22bd07;
}
.ul-disc ul{list-style-type:disc;}
.table-caption{
    font-size: 16px;
    color: #000;
    line-height: 21px;
    text-align: left;
    display: block;
    padding-bottom: 10px;
}
.sticky-sidebar{
    position: sticky;
    top: 5%;
}

.post-user{
    height: 56px;
    width: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.links-block .link-item {
    list-style-type: none;
    margin-bottom: 5px;
  }
  
  .links-block .heading-level-2 {
    /* H2 level */
    padding-left: 0px;
  }
  
  .links-block .heading-level-3 {
    /* H3 level */
    padding-left: 15px; /* Adjust as needed */
  }
  
  .links-block .heading-level-4 {
    /* H4 level */
    padding-left: 30px; /* Adjust as needed */
  }


/* .back-btn:hover{color: var(--green);} */
@media (max-width: 991px) {
.post-sec .container>.row{grid-template-columns:1fr;gap:60px}
section{margin-bottom:100px}
.footer-component{padding-top:100px;padding-right:60px}
.footer-component img{width:300px}
.related-posts .row.ps-0 {
    --bs-gutter-x: 1.5rem;
}
}
@media (max-width: 767px) {
.client-expectations-sec .row{align-items:flex-start;flex-direction:column-reverse;gap:40px}
.client-expectations-sec .row>div{width:100%;text-align:center}
h1.title{font-size:30px;line-height:40px;margin-bottom:15px}
.header{padding:18px 0}
.join-sec p{margin-bottom:22px;font-size:16px;line-height:22px}
.container .btn{font-size:16px;padding:8px 20px;height:50px;line-height:32px}
.join-sec:before{width:auto}
section{margin-bottom:60px}
.title.h2{font-size:28px;line-height:38px;margin-bottom:15px}
.img-with-text p{font-size:18px;line-height:26px}
.img-with-text p,.footer-main-content p{font-size:18px;line-height:26px}
.post-main>.row{margin-inline:-10px;row-gap:20px}
.post-main>.row>li{width:100%;padding:0 10px}
.post-sec h2.title{margin-bottom:35px}
.post-card .content .title{font-size:18px;line-height:24px;margin-bottom:18px;min-height:72px}
body .join-sec .btn{min-width:220px}
.post-card .content{padding:12px 20px 20px}
body .post-card .btn{font-size:14px;line-height:22px;height:40px;padding:8px 15px;min-width:110px}
.post-main .btn.fluid{margin-top:20px}
.post-sec .container>.row{gap:40px}
.title.h3{font-size:22px;line-height:30px;margin-bottom:8px}
.post-sec .post-aside{padding:20px!important}
.social-media .social-icon{width:40px;height:40px}
.social-media{gap:8px}
.post-sec .post-aside>div{margin-bottom:20px}
.links-block{margin:10px 0 0}
.links-block .link-item{margin:6px 0}
.links-block .link-item a{padding:2px 0 2px 22px}
.links-block .link-item a:before{width:9px;height:9px}
.popular-post_item{margin:6px 0}
.popular-post_item-wrapper{margin-bottom:0}
.footer-main{padding:50px 0 0}
.newsletter{grid-template-columns:1fr 140px;gap:15px;margin-top:40px}
.newsletter input{padding:8px 20px;font-size:18px;height:50px}
.footer-component{padding-top:60px;padding-right:30px}
.footer-component img{width:200px}
/* .copyright .social-media .social-icon{width:26px;height:26px} */
.copyright p{font-size:15px}
.copyright{padding:10px 0}
.blog-post-detail .blog-detail-title{font-size:30px}
.post-info{font-size:16px}
.post-title-detail{font-size:24px}
.post-content p{font-size:14px;line-height:1.8}
.related-posts .post-card .content .title{font-size:15px}
.post-card{
    height: auto;
    margin-top: 20px;
}
.back-btn{font-size:14px}
}
.back-btn:hover{
    color: var(--gray);
}
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

@media (max-width: 479px) {
header .logo{font-size: 16px;white-space: nowrap;}
header .logo img{max-width:160px}
.header{padding:14px 0}
.join-sec:before{width:auto;top:50px}
.header .btn.secondary{font-size:14px;padding:6px 16px;line-height:22px}
.newsletter{grid-template-columns:1fr}
.post-main>.row{grid-template-columns:1fr}
.post-card .content .title{min-height:auto;text-overflow:initial;display:block}
h1.title{font-size:26px;line-height:32px;margin-bottom:15px}
.join-sec .content-block{padding:30px 0 40px}
section{margin-bottom:40px}
.title.h2{font-size:24px;line-height:32px;margin-bottom:10px}
.join-sec p{margin-bottom:18px;font-size:15px;line-height:20px}
.img-with-text p,.footer-main-content p{font-size:16px;line-height:24px}
.client-expectations-sec .row{gap:25px}
.post-sec h2.title{margin-bottom:25px}
.footer-main{padding:40px 0 0}
.newsletter{gap:12px;margin-top:30px}
.footer-component{padding-top:40px;padding-right:20px}
.footer-component img{width:140px}
.copyright p{font-size:14px}
.copyright .row{align-items:center;flex-direction:column-reverse;gap:6px;text-align:center}
.copyright .row>div{width:100%;text-align:center!important}
.copyright .social-media{justify-content:center}
.newsletter input{font-size:16px}
.blog-post-detail .blog-detail-title{font-size:24px}
.post-info{font-size:15px}
.post-title-detail{font-size:20px}
.related-posts .post-card .content .title{font-size:15px}
}
@media (max-width: 359px) {
.join-sec:before{top:80px}
header .logo img{max-width:140px}
.header .btn.secondary{font-size:13px;padding:6px 11px}
body .join-sec .btn{min-width:200px}
}
